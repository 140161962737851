import React from 'react';
import './../assets/styles/Scholarship.css';
import ListCard from './ListCard';

const Scholarship = (props) => {
  let i = 0;
  const next = () => {
    return ++i;
  }

  const scholarship = {
    'English': [
      {
        id: next(),
        title : "School of Advanced New Technologies - FRANCE",
        year : "2024",
        certificate : "Currently",
        summary: [
          "Master of Science 1&2 : Software Architect, Application Developer - IDV ."
        ],
        website: "https://www.etna.io"
      },
      {
        id: next(),
        title : "UNIVERSITY OF STRASBOURG - FRANCE",
        year : "2024",
        certificate : "Mathematics-Computers Sciences",
        summary: [
          "Bachelor's Degree."
        ],
        website: "https://www.unistra.fr"
      },
      {
          id: next(),
          title : "VIRTUAL UNIVERSITY OF IVORY COAST - IVORY COAST",
          year : "2017 to 2018",
          certificate : "Applications Development and e-services curriculum.",
          summary: [
            ""
          ],
          website: "https://www.uvci.edu.ci"
      },
      {
          id: next(),
          title : "MODERNE HIGH SCHOOL ADJAMÉ HARRIS - IVORY COAST",
          year : "2016",
          certificate : "General Curriculum.",
          summary: [
            "Scientific Baccalaureate."
          ],
          website: "#"
      },
      {
          id: next(),
          title : "MUNICIPAL HIGH SCHOOL ADJAMÉ WILLIAMSVILLE - IVORY COAST",
          year : "2013",
          certificate : "General Curriculum.",
          summary: [
            "Undergraduate Diploma."
          ],
          website: "#"
      }
    ],
    'French': [
      {
        id: next(),
        title : "École des Technologies Nouvelles Avancées - FRANCE",
        year : "2024",
        certificate : "En cours",
        summary: [
          "Master Scientifique 1&2 : Architecte logiciel, développeur d’application - IDV ."
        ],
        website: "https://www.etna.io"
      },
      {
        id: next(),
        title : "UNIVERSITÉ DE STRASBOURG - FRANCE",
        year : "2024",
        certificate : "Mathématique-Informatique",
        summary: [
          "Licence Informatique."
        ],
        website: "https://www.unistra.fr"
      },
      {
          id: next(),
          title : "UNIVERSITÉ VIRTUELLE DE CÔTE D'IVOIRE - CÔTE D'IVOIRE",
          year : "2017 à 2018",
          certificate : "Cursus Développement D'Applications et E-Services.",
          summary: [
            ""
          ],
          website: "https://www.uvci.edu.ci"
      },
      {
          id: next(),
          title : "LYCÉE MODERNE ADJAMÉ HARRIS - CÔTE D'IVOIRE",
          year : "2016",
          certificate : "Cursus Général.",
          summary: [
            "Baccalauréat Scientifique."
          ],
          website: "#"
      },
      {
          id: next(),
          title : "LYCÉE MUNICIPAL ADJAMÉ WILLIAMSVILLE - CÔTE D'IVOIRE",
          year : "2013",
          certificate : "Cursus Général.",
          summary: [
            "Brevet D'Étude Du Premier Cycle."
          ],
          website: "#"
      }
    ]    
  }

  return (
    <>
      <section className='scholarship-container'>
        {
          scholarship[props.lang].map(item => {
            return (
              <div key={item.id} className='degree-container'>
                <ListCard XP_DEGREE={item} />
              </div>
              )  
            })
        }
      </section>
    </>
  );
}

export default Scholarship;
