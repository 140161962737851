import React, { useEffect, useState } from 'react';
import './../assets/styles/Footer.css';

const Footer = (props) => {
  
  const [copyright, setCopyright] = useState('');

  useEffect(() => {
    (props.lang === 'English') ? 
      setCopyright('All rights reserved') : 
      setCopyright('Tous Droits Réservés');

  }, [props.lang]);

  const author = props.author;

  return (
    <>
      <footer>
        <p>Copyright &copy; {author} - {copyright}.</p>
      </footer>
    </>
  );
}

export default Footer;
