import React, { useState } from "react";
import './../assets/styles/Form.css';
import subscribers from './data/json/newsletters.json'; // Deploy
// import subscribers from './../data/json/newsletters.json'; // Dev
import Info from './../components/Info';


const Form = ({children}) => {

  const FormTypes = ['Newsletter', 'ContactForm'];
  const inputs = document.querySelectorAll('input');
  const textareas = document.querySelectorAll('textarea');
  const [state, setState] = useState('');

  const Reset = () => {
    
    inputs?.forEach(item => {
      const inputType = item.getAttribute('type');
      if( inputType !== 'hidden') {
        item.classList = "";
        item.previousElementSibling.click();
      }
    });

    textareas?.forEach(item => {
      const inputType = item.getAttribute('type');
      if( inputType !== 'hidden') {
        item.classList = "";
        item.previousElementSibling.click();
      }
    });

  }
  
// Put your Script folder on root of the App Deploy version
  const links = {
    'newsletter' : './scripts/php/newsletter.php',
    'contactform' : './scripts/php/send.php'
  }
  // Dev
  // const links = {
  //   'Newsletter' : './../scripts/php/newsletter.php',
  //   'ContactForm' : './../scripts/php/send.php'
  // } 
  
  const submitHandler = async (e) => {
    e.preventDefault();

    let formType = e.target.querySelector('input[type="hidden"]')?.getAttribute('value');
    
    const type = (FormTypes.includes(formType)) ? formType : '';
    const url = links[formType.toString().toLowerCase()];
    
    const HeadersConfig = new Headers(
      {
          "X-Custom-Header": "ProcessThisImmediately",
          'Content-Type': 'application/json'
      }
    );

    let data = {};

    const inputs = e.target.querySelectorAll('input');
    const textareas = e.target.querySelectorAll('textarea');
    
    inputs?.forEach(item => {
      const inputType = item.getAttribute('type');
      if( inputType !== 'hidden') {
        data[item.name.toLowerCase()] = verify(item.value);
      }
    });

    textareas?.forEach(item => {
      const inputType = item.getAttribute('type');
      if( inputType !== 'hidden') {
        data[item.name.toLowerCase()] = verify(item.value);
      }
    });
    
    const res = await fetch(url, {
        method: "POST",
        headers: HeadersConfig,
        mode: "cors",
        cache: "no-cache", 
        credentials: "same-origin",
        body: JSON.stringify(data),
      }
    );
    
    if(res.ok){
      setState('success');

      if(type === 'Newsletter'){
        subscribers.users.push({
          ...data
        });
      }

      setTimeout(() => {
        setState('');
        Reset();
      }, 1000);
    } else {
      setState('failure');
      setTimeout(() => {
        setState('');
        // Reset(); // Dev
      }, 1000);
    }
  }

  return (
    <>    
      <section className='form-container'>
        <Info type={state} />
        <form onSubmit={(e) => submitHandler(e)} action="#" method="POST" encType="multipart/form-data">
          {
            children
          }
        </form>
      </section>
    </>
  )

}

const verify = (value) => {
  return value.toString().trim()
}

export default Form;