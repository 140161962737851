import React from "react";
import 'primeicons/primeicons.css';

import './../assets/styles/IEval.css'

const IEval = (props) => {

  return (
    <>
      <section className={`ieval-container ${props.type}`}>
        <div className={`ieval ${props.type}`}>
          {
            props.type === 'failure' ?  
            <i className="pi pi-exclamation-triangle"></i> :
            <i className="pi pi-verified"></i>
          }
          {
            (props.type === 'failure') ? 
            'Format non valide'
            : 'Format valide'
          }
        </div>
      </section>
    </>
  )

}

export default IEval;