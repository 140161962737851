import React from "react";
import { useState } from 'react';
import './../assets/styles/Field.css';
import IEval from './IEval'; // Input Evaluation

const Field = (props) => {
  const [err, setError] = useState(false);
  const [input, setInput] = useState('');

  
  const nameRegex = /^([a-zA-Z]{2,})+( [a-zA-Z]{2,})*$/;
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const telRegex = /^\+?([0-9]{1,3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})([-. ]?([0-9]{2}))?$/;
  
  const FormTypes = ['Newsletter', 'ContactForm'];

  const reset = (e) => {
    e.preventDefault();
    setInput('');
  }

  const validation = (e) => {
    const value = e.target.value;
    let test;

    e.target.className = (value === '') ? 'empty' : '';
    setInput(value);

    switch (e.target.type) {
      case 'text':
        test = nameRegex.test(value);
        setError(!test);
        break;
      
      case 'email':
        test = emailRegex.test(value);
        setError(!test);
        break;

      case 'tel':
        test = telRegex.test(value);
        setError(!test);
        break;
    
      default:
        console.error("Input type Not found");
        break;
    }
  
    e.target.className = test ? 'valid' : 'invalid';
  }

  const params = props;
  const requirement = (props.type === 'hidden') ? false : true;
  const formType = (FormTypes.includes(params.formType)) 
  ? params.formType : '';

  
  return (
    <div className={`input-field-container ${!requirement ? 'hidden' : ''}`}>
      <div className={`input-field ${!requirement ? 'hidden' : ''}`}>
          <button onClick={(e) => reset(e)} className="btn-reset"></button>
          {requirement ? 
            (
              <input type={params.type} value={input} 
                onChange={(e) => { validation(e); }} name={params.label} 
                placeholder={params.placeholder} id={params.label} 
                required='required'
              />) :
            (
              <input type={params.type} value={formType} 
                onChange={(e) => { validation(e); }} name={params.label} 
                placeholder={params.placeholder} id={params.label} 
              />
            )
          }
          
          <br />
          <label htmlFor={params.label}>{params.labelText}</label>
          <div className='eval-input-container'>
          {
            (input === '') 
            ? '' : (err && input !== '') 
            ? <IEval type={'failure'} /> : <IEval type={'success'} />
          }
          </div>
      </div>
    </div>
  )

}

export default Field;