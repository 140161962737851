import React from "react";
import Form from './Form';
import Field from '../components/Field';
import TextAreaField from '../components/TextAreaField';
import Button from '../components/Button';
import CircleBtn from '../components/CircleBtn';

import './../assets/styles/Newsletter.css'

const Newsletter = (props) => {

  const texts = {
    'English' :{
      'name': 'Enter your name (Entreprise name)',
      'email': 'Enter your email',
      'tel': 'Enter your contact',
      'message': 'Enter your message',
      'submit': 'Send',
    },
    'French' :{
      'name': 'Entrer votre nom (Entreprise)',
      'email': 'Entrer votre email',
      'tel': 'Entrer votre contact',
      'message': 'Entrer votre message',
      'submit': 'Envoyez',
    }
  }

  return (
    <>
      <section className='contact-container'>
        <Form>
          <CircleBtn type={'close'} />
          <Field type={'hidden'} label={'formType'} formType={'ContactForm'} />
          <Field type={'text'} label={'name'} labelText={texts[props.lang]['name']} />
          <Field type={'email'} label={'email'} labelText={texts[props.lang]['email']} />
          <Field type={'tel'} label={'tel'} labelText={texts[props.lang]['tel']} />
          <TextAreaField type={'message'} label={'message'} labelText={texts[props.lang]['message']} />
          <Button type={'submit'} btnText={texts[props.lang]['submit']} />
        </Form>
      </section>
    </>
  )

}

export default Newsletter;