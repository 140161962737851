import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Header from './components/Header';
import About from './components/About';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Scholarship from './components/Scholarship';
import Skills from './components/Skills';
import XP from './components/XP';
import Error404 from './components/Error404';

import Newsletter from './layouts/Newsletter';
import ContactForm from './layouts/ContactForm';

import FR from './assets/flags/FR.svg';
import EN from './assets/flags/EN.svg';
import './assets/styles/TradCard.css';

// import Home from './layouts/Home';

import './App.css';
import CircleBtn from './components/CircleBtn';

function App() {

  const [lang, setLanguage] = useState('French');
  const author = 'Touch ID'
  const User = {
    name: 'Ibrahim DIABY',
    job: {
      'English': 'Web &amp; Mobile Developer',
      'French': 'Développeur Web &amp; Mobile',
    },
    summary: {
      'English': [
        `
          I'm Ibrahim Diaby, Web, Mobile & Desktop Developer. <br />
          I also help train future webmasters. <br />
          I have experience of the most widely used modern programming languages for web, mobile and desktop applications. <br />
          SQL and NoSQL databases. JavaScript frameworks such as ReactJS. <br />
          Use of imperative, functional and procedural programming languages. <br />
          Use of the MVC principle (Model - View - Controller). <br />
          Programming techniques : <br />
          OOP (Object Oriented Programming) <br />
          FOP (Functional Oriented Programming) <br />
          POP (Procedural Oriented Programming) <br />
          AOP (Aspect Object Programming). <br />
          I also work in Freelancer.
        `
      ],
      'French': [
        `
          Je suis Ibrahim Diaby Developpeur Web, Mobile &amp; Desktop. <br />
          J'interviens également dans le cadre de la formation du futurs webmasters. <br />
          J'ai une expérience des langages de programmation modernes les plus utilisés pour les applications web, mobile et desktop. <br />
          Les bases de données SQL et NoSQL. Les frameworks JavaScript tels que ReactJS. <br />
          L'utilisation de langages de programmation de type impératif, fonctionnel, procédural. <br />
          L'utilisation du principe MVC (Model - View - Controller). <br />
          Techniques de programmation :  <br />
          OOP (Object Oriented Programming) <br />
          FOP (Functional Oriented Programming) <br />
          POP (Procedural Oriented Programming) <br />
          AOP (Aspect Object Programming). <br />
          J'exerce également dans le FREELANCE.
        `
      ]
    },
    socialLinks: {
      X: "https://x.com/diabyib31422230?s=21",
      Youtube: "https://youtube.com/@NeoMatriXID?si=XHAH3nVjOyEjALoz",
      Facebook: "https://www.facebook.com/MasterDigitMasterID",
      LinkedIn: "https://www.linkedin.com/in/ibrahim-diaby-023474144",
      Messenger: "https://m.me/942931992530164",
      Instagram: "https://www.instagram.com/touchid007?igsh=dmhhMzQ1cGlnaTNr&utm_source=qr",
      // Whatsapp: "https://wa.me/message/PANVTHREIXQTD1",
    }
  }

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);


  const languages = [
    {
      id : 1,
      short : 'Fr',
      language : 'French',
      src: FR
    },
    {
      id : 2,
      short : 'En',
      language : 'English',
      src: EN
    }
  ];

  return (
    <div className="App">
      <>
        <Newsletter lang={lang} />
        <ContactForm lang={lang} />
        <Header lang={lang} />
          <main>
            <About lang={lang} User={User} />
            <BrowserRouter>
              <Nav lang={lang} />
              <Routes>
                <Route path='/'>
                  <Route index='true' path="/" element={<XP lang={lang} />} />
                  <Route path="xp" element={<XP lang={lang} />} />
                  <Route path="skills" element={<Skills lang={lang} />} />
                  <Route path="scholarship" element={<Scholarship lang={lang} />} />
                </Route>
                <Route path="*" element={<Error404 lang={lang} />} />
              </Routes>
            </BrowserRouter>
            <div className='trad-container'>
            {
              languages.map(item => {
                return (
                  <figure onClick={() => {setLanguage(item.language)}} key={item.id}>
                    <img key={item.id} src={item.src} alt={item.language} srcSet={item.src} />
                  </figure>
                )
              })
            }
            </div>
          </main>
          <CircleBtn type={'newsletter'} />
          <CircleBtn type={'contact'} />
        <Footer lang={lang} author={author} />
      </>
    </div>
  );
}

export default App;
