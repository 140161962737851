import React from 'react';
import { useState, useEffect } from 'react';
import './../assets/styles/Error404.css';

const Error404 = (props) => {
  const [text, setText] = useState('');

  useEffect(() => {
    (props.lang === 'English') ? 
      setText('Page Not Found') : 
      setText('Page Introuvable');
  }, [props.lang]);
  return (
    <>
      <section className='error'>
       <h1>404 - {text}</h1>
      </section>
    </>
  );
}

export default Error404;
