import React from "react";
import Form from './Form';
import Field from './../components/Field';
import Button from './../components/Button';
import CircleBtn from './../components/CircleBtn';

import './../assets/styles/Newsletter.css'

const Newsletter = (props) => {

  const texts = {
    'English' :{
      'name': 'Enter your name (Entreprise name)',
      'email': 'Enter your email',
      'tel': 'Enter your contact',
      'submit': 'Subscribe',
    },
    'French' :{
      'name': 'Entrer votre nom (Entreprise)',
      'email': 'Entrer votre email',
      'tel': 'Entrer votre contact',
      'submit': 'Soumettre',
    }
  }

  return (
    <>
      <section className='newsletter-container'>
        <Form>
          <CircleBtn type={'close'} />
          <Field type={'hidden'} label={'formType'} formType={'Newsletter'} />
          <Field type={'text'} label={'name'} labelText={texts[props.lang]['name']} />
          <Field type={'email'} label={'email'} labelText={texts[props.lang]['email']} />
          <Field type={'tel'} label={'tel'} labelText={texts[props.lang]['tel']} />
          <Button type={'submit'} btnText={texts[props.lang]['submit']} />
        </Form>
      </section>
    </>
  )

}

export default Newsletter;