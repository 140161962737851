import React from "react";
import 'primeicons/primeicons.css';

import './../assets/styles/Info.css'

const Info = (props) => {

  return (
    <>
      <section className={`info-container ${props.type}`}>
        <div className={`info ${props.type}`}>
          {
            props.type === 'failure' ?  
            <i className="pi pi-exclamation-triangle"></i> :
            <i className="pi pi-verified"></i>
          }
          {
            (props.type === 'failure') ? 
            'Erreur lors de l\'envoi'
            : 'Envoyé avec succès'
          }
        </div>
      </section>
    </>
  )

}

export default Info;