import React, { useEffect } from 'react';
import { useState } from 'react';
import CV_FR from './../assets/cv/CV_FR.pdf';
import CV_EN from './../assets/cv/CV_EN.pdf';
import './../assets/styles/Header.css';
import logo from './../assets/images/logo.png';
import 'primeicons/primeicons.css';

const Header = (props) => {

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [CV, setCV] = useState('');

  useEffect(() => {
    (props.lang === 'English') ? 
      setTitle('Ibrahim DIABY Curriculum Vitae') : 
      setTitle('Curriculum Vitae d\'Ibrahim DIABY');

    (props.lang === 'English') ? 
      setText('Download CV') : 
      setText('Télécharger CV');

    (props.lang === 'English') ? 
      setCV(CV_EN) : 
      setCV(CV_FR);
  }, [props.lang]);

  return (
    <>
      <header>
        <div className='logo-container'>
          <img src={logo} alt="Logo" srcSet={logo} />
        </div>
        <div className='download-container'>
          <a href={CV} rel='noopener noreferrer' download={title} className='download-btn'>
            {text} <i className="pi pi-download"></i>
          </a>
        </div>
      </header>
    </>
  );
}

export default Header;
