import React from 'react';
import 'primeicons/primeicons.css';
import './../assets/styles/Button.css';
import './../assets/styles/CircleBtn.css';

const CircleBtn = (props) => {

  const icons = {
    'close':'times',
    'newsletter':'envelope',
    'contact':'comments',
  }

  const key = Object.keys(icons)
                .filter(item => item === props.type)[0]

  const closeModal = (e) => {
    e.preventDefault();

    let modal;

    switch (key) {
      case 'newsletter':
        modal = document.querySelector('.newsletter-container');
        break;

      case 'contact':
        modal = document.querySelector('.contact-container');
        break;

      case 'close':
        modal = document.querySelector('.contact-container.visible') || document.querySelector('.newsletter-container.visible');
        break;
    
      default:
        modal = null;
        break;
    }

    modal.classList.remove('visible');
    modal.classList.add('hidden');
  }

  const openModal = (e) => {
    e.preventDefault();

    let modal;

    switch (key) {
      case 'newsletter':
        modal = document.querySelector('.newsletter-container');
        break;

      case 'contact':
        modal = document.querySelector('.contact-container');
        break;
    
      default:
        modal = null;
        break;
    }

    modal?.classList.remove('hidden');
    modal?.classList.add('visible');
  }
  return (
    <>
      <section className={`button-container ${key}`}>
        <button className={`${key} round`}
          onClick={(e) => {
            key === 'close' ? closeModal(e) : openModal(e);
          }}
          type={props.type}>
          <i className={`pi pi-${icons[key]}`}></i>
        </button>
      </section>
    </>
  );
}

export default CircleBtn;
