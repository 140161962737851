import React from "react";
import { useState } from 'react';
import './../assets/styles/TextAreaField.css';
import IEval from './IEval'; // Input Evaluation

const TextAreaField = (props) => {
  const [err, setError] = useState(false);
  const [message, setMessage] = useState('');

  const reset = (e) => {
    e.preventDefault();
    setMessage('');
  }

  const validation = (e) => {
    const value = e.target.value;

    e.target.className = (value === '') ? 'empty' : '';
    setMessage(value);

    (message.length < 50) ? setError(true) : setError(false); 
  
    e.target.className = !err ? 'valid' : 'invalid';
  }

  const params = props;
  
  return (
    <div className="textarea-field-container">
      <div className="textarea-field">
          <button onClick={(e) => reset(e)} className="btn-reset"></button>
          <textarea 
            type={params.type} value={message} 
            onChange={(e) => { validation(e); }} name={params.type} 
            placeholder={params.placeholder} id={params.label} 
            required='required' ></textarea> 
          
          <br />
          <label htmlFor={params.label}>{params.labelText}</label>
          <div className='eval-input-container'>
          {
            (message === '') 
            ? '' : (err && message !== '') 
            ? <IEval type={'failure'} /> : <IEval type={'success'} />
          }
          </div>
      </div>
    </div>
  )

}

export default TextAreaField;