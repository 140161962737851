import React from 'react';
import './../assets/styles/Card.css';

const Card = (props) => {

    const skills = props.Skills;
    return (
      <>
        <div className='card-container' onClick={() => {
          window.open(skills.website, '_blank');
        }}>
          <div className='card-up'>
            <div className="card-title">
              <h4>{skills.language}</h4>
            </div>
            <div className='card-img'>
              <figure>
                <img src={skills.src} alt={skills.language} srcSet={skills.src} />
              </figure>
            </div>
          </div>
          <div className='card-down'>
            <div className='card-content'>
              <p className='card-summary'>
                {
                  skills.certificate
                } 
              </p>
            </div>
          </div>
        </div>
      </>
    )
}

export default Card;