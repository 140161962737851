import React from "react";

import './../assets/styles/Button.css'

const Button = (props) => {

  return (
    <>
      <section className='button-container'>
        <button type={props.type}>{props.btnText}</button>
      </section>
    </>
  )

}

export default Button;