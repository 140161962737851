import React from 'react';
import Card from './Card';
import './../assets/styles/Skills.css';

import bash from './../assets/images/programming/bash.svg';
// import kotlin from './../assets/images/programming/kotlin.svg';
import css3 from './../assets/images/programming/css3.svg';
import sass from './../assets/images/programming/sass.svg';
import git from './../assets/images/programming/git.svg';
import html5 from './../assets/images/programming/html5.svg';
import java from './../assets/images/programming/java.svg';
import javascript from './../assets/images/programming/javascript.svg';
import typescript from './../assets/images/programming/typescript.svg';
import json from './../assets/images/programming/json.svg';
import nodejs from './../assets/images/programming/nodejs.svg';
import php from './../assets/images/programming/php.svg';
import python from './../assets/images/programming/python.svg';
import react from './../assets/images/programming/react.svg';
import reactnative from './../assets/images/programming/react.svg';
import c from './../assets/images/programming/c.svg';
import cplusplus from './../assets/images/programming/cplusplus.svg';
import flutter from './../assets/images/programming/flutter.svg';
// import csharp from './../assets/images/programming/csharp.svg';
// import qt from './../assets/images/programming/qt.svg';
// import ionic from './../assets/images/programming/ionic.svg';

const Skills = (props) => {
  let i = 0;

  const next = () => {
    return ++i;
  }

  const skills = [
    {
      id: next(),
      language: 'Bash',
      src: bash,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification BASH'
    },
    {
      id: next(),
      language: 'HTML5',
      src: html5,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification HTML'
    },
    {
      id: next(),
      language: 'CSS3',
      src: css3,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification CSS'
    },
    {
      id: next(),
      language: 'SASS',
      src: sass,
      year: '2023',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification SASS'
    },
    {
      id: next(),
      language: 'JAVASCRIPT',
      src: javascript,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification JAVASCRIPT'
    },
    {
      id: next(),
      language: 'TYPESCRIPT',
      src: typescript,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification TYPESCRIPT'
    },
    {
      id: next(),
      language: 'PHP',
      src: php,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification PHP'
    },
    {
      id: next(),
      language: 'C',
      src: c,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification C'
    },
    {
      id: next(),
      language: 'C++',
      src: cplusplus,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification C++'
    },
    {
      id: next(),
      language: 'PYTHON',
      src: python,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification PYTHON'
    },
    {
      id: next(),
      language: 'GIT',
      src: git,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification GIT'
    },
    // {
    //     id: next(),
    //     language: 'Qt/Quick',
    //     src: qt,
    //     year: '2020',
    //     website: '#',
    //     status: 'En Cours',
    //     certificate: 'Certification Qt/Quick'
    // },
    // {
    //     id: next(),
    //     language: 'Kotlin',
    //     src: kotlin,
    //     year: '2020',
    //     website: '#',
    //     status: 'En Cours',
    //     certificate: 'Certification KOTLIN'
    // },
    {
      id: next(),
      language: 'JAVA',
      src: java,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification JAVA'
    },
    {
      id: next(),
      language: 'REACT',
      src: react,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification REACT'
    },
    {
      id: next(),
      language: 'REACT NATIVE',
      src: reactnative,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification REACT NATIVE'
    },
    {
      id: next(),
      language: 'FLUTTER',
      src: flutter,
      year: '2024',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification Flutter'
    },
    {
      id: next(),
      language: 'NODEJS',
      src: nodejs,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification NODEJS'
    },
    {
      id: next(),
      language: 'JSON',
      src: json,
      year: '2020',
      website: '#',
      status: 'En Cours',
      certificate: 'Certification JSON'
    },
    // {
    //     id: next(),
    //     language: 'C#',
    //     src: csharp,
    //     year: '2020',
    //     website: '#',
    //     status: 'En Cours',
    //     certificate: 'Certification C#'
    // },
    // {
    //     id: next(),
    //     language: 'IONIC',
    //     src: ionic,
    //     year: '2020',
    //     website: '#',
    //     status: 'En Cours',
    //     certificate: 'Certification IONIC'
    // }

  ]
  return (
    <>
      <section className='skills'>
        <section className='skills-container'>
          {
            skills.map(item => {
              return (
                <Card key={item.id} Skills={item} />
              )
            })
          }

        </section>
      </section>
    </>
  );
}

export default Skills;
