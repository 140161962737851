import React, { useEffect, useState } from 'react';
import 'primeicons/primeicons.css';
import './../assets/styles/Nav.css';
import { Link, useLocation } from 'react-router-dom';

const Nav = (props) => {


  const location = useLocation();

  const [xp, setXP] = useState('');
  const [scholarship, setScholarship] = useState('');
  const [skills, setSkills] = useState('');

  useEffect(() => {
    if (props.lang === 'English') {
      setXP('Projects');
      setScholarship('Scholarship');
      setSkills('Skills');
    } else {
      setXP('Projets');
      setScholarship('Parcours Scolaires');
      setSkills('Compétences');
    }
  }, [props.lang]);

  const setActive = (index) => {
    let a = document.querySelectorAll('nav a');
    a.forEach(item => {
      item.classList.remove('active');
    });

    a[index].classList.add('active');
  }

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/XP") { setActive(0); }
    else if (location.pathname === "/Scholarship") { setActive(1); }
    else { setActive(2); }
  }, [location]);

  return (
    <>
      <section>
        <nav>
          <Link onClick={() => { setActive(0) }} className='link' index='true' to='/XP'><i className="pi pi-bookmark"></i> {xp}</Link>
          <Link onClick={() => { setActive(1) }} className='link' to='/Scholarship'><i className="pi pi-book"></i> {scholarship}</Link>
          <Link onClick={() => { setActive(2) }} className='link' to='/Skills'><i className="pi pi-code"></i> {skills}</Link>
        </nav>
      </section>
    </>
  );
};


export default Nav;