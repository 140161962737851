import React from 'react';
import picture from './../assets/images/Face.jpg';
import ReactHtmlParser from 'react-html-parser';
import './../assets/styles/About.css';
import Social from './Social';

const About = (props) => {
  const User = props.User;
  const socialKeys = Object.keys(User.socialLinks);
  
  return (
    <>
      <section className='about'>
        <div className='about-me'>
            <div className="name">
              {/* <h1>{User.name}</h1> */}
              <svg viewBox='0 0 1000 100' xmlns="http://www.w3.org/2000/svg">
                <text x={'35%'} y={'70%'} className='name-text animate'>{User.name}</text>
              </svg>
            </div>
          <div className="job">
            {/* <h3>{ReactHtmlParser(User.job[props.lang])}</h3> */}
            <svg viewBox='0 0 1000 80' xmlns="http://www.w3.org/2000/svg">
              <text x={'25%'} y={'50%'} className='name-text animate'>{ReactHtmlParser(User.job[props.lang])}</text>
            </svg>
          </div>
          <div className="summary">
            <p>
              {ReactHtmlParser(User.summary[props.lang][0])}
            </p>
          </div>
        </div>
        <div className='picture-me'>
          <figure>
            {
              socialKeys.map(item => {
                return (
                  <Social type={item} key={item} link={User.socialLinks[item]} />
                )
              })
            }
            <img src={picture} alt='TouchID presentation' srcSet={picture} />
          </figure>
        </div>
      </section>
    </>
  );
}

export default About;
