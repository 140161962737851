import React from 'react';
import './../assets/styles/Social.css';
// import socialImg from './../assets/images/social/img.mjs'
import X from './../assets/images/social/X.svg'
import Youtube from './../assets/images/social/Youtube.svg'
import Facebook from './../assets/images/social/Facebook.svg'
import LinkedIn from './../assets/images/social/LinkedIn.svg'
import Messenger from './../assets/images/social/Messenger.svg'
import Instagram from './../assets/images/social/Instagram.svg'
import Whatsapp from './../assets/images/social/Whatsapp.svg'

const Social = (props) => {

  const website = () => {
    window.open(props.link, '_blank');
  }

  const socialImg = {
    'X': X,
    'Youtube': Youtube,
    'Facebook': Facebook,
    'LinkedIn': LinkedIn,
    'Messenger': Messenger,
    'Instagram': Instagram,
    'Whatsapp': Whatsapp,
  } 

  return (
    <>
      <div className="social-container animate" onClick={() => {website()}}>
        <figure>
          <img src={socialImg[props.type]} alt="" key={props.type} />
        </figure>
      </div>
    </>
  );  
}


export default Social;