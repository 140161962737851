import React from 'react';
import HtmlParser from 'react-html-parser';
import './../assets/styles/ListCard.css';

const ListCard = (props) => {
  const Road = props.XP_DEGREE;
  const website = (url) => {
    window.open(url, '_blank');
  }

  return (
    <>
      <div onClick={() => {website(Road.website)}} className="list-container">
        <div className='list-title-container'>
          <h3>{HtmlParser(Road.title)}</h3>
        </div>
        <div className='list-year-container'>
          <span>
            {HtmlParser(Road.year)} - {HtmlParser(Road.certificate)}
          </span>
        </div>
        <div className='list-summary-container'>
          {
            Road.summary.map(item => {
              return (
                <p key={item}>
                  {HtmlParser(item)}
                </p>
              )
            })
          }
            {/* {HtmlParser(Road.summary)} */}
        </div>
      </div>
    </>
  );
}

export default ListCard;
